<template>
  <div class="MyBreadcrumbitem" :class="{ active: active }">

      <a @click="go" v-if="!isDisabled">
        <slot></slot>
      </a>
      <span v-if="isDisabled">
          <slot></slot>
      </span>

  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods:{
    go(){
      this.$router.replace({
        path:this.to
      })
    }
  },
  props: {
    //分隔符
    to: {
      type: String,
      default: "/",
    },

    //是否活跃
    active: {
      type: Boolean,
      default: false,
    },

    //是否警用点击
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang='scss' scoped>

.MyBreadcrumbitem {
  a {
    color: #424245;
    text-decoration: none;
    font-size: 0.75rem;
    cursor: pointer;
  }
  span {
    color: #424245;
    font-size: 0.75rem;
  }
}

.active {
  a {
    color: #888;
  }

  span {
    color: #888;
  }
}
</style>