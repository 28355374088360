<template>
  <div class="MyBreadcrumb" ref="MyBreadcrumb">
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.addIco();
  },

  methods: {
    //给每个面包屑选项后添加图标
    addIco() {
      let child = this.$refs.MyBreadcrumb.children;
      for (let i = 0; i < child.length - 1; i++) {
        let elem = document.createElement("i");
        elem.className = this.separator;
        elem.style.fontSize = this.fontsize;
        elem.style.color = this.color;
        elem.style.marginLeft = '0.3rem'
        elem.style.marginRight = '0.3rem'
        child[i].appendChild(elem);
      }
    },
  },
  props: {
    //分隔符
    separator: {
      type: String,
      default: "iconfont icon-jiantou_liebiaoxiangyou",
    },
    //字体大小
    fontsize: {
      type: String,
      default: "0.75rem",
    },
    //颜色
    color: {
      type: String,
      default: "#202435",
    },
  },
};
</script>
<style lang='scss' scoped>

.MyBreadcrumb {
  display: flex;
}
</style>