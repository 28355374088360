<template>
<div class="hint">
<strong class="foo">Error:</strong><slot></slot>
</div>
</template>
<script>
export default {
data(){
return {
}
},
props:{
info:{
    type:String
}
},
created(){
},
}
</script>
<style lang='scss' scoped>

.hint{
    border:1px solid var(--border-color-default);
    padding: 1rem;
    font-size:0.875rem;
    color:var(--text-color-minor);
 .foo{
     color:var(--text-color-title);
     margin-right:0.3rem;
 }
}
</style>