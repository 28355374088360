<template>
  <div class="account">
    <div class="container">
      <my-breadcrumb>
        <my-breadcrumb-item to="/">{{
          $t("Goods.Pages.Home")
        }}</my-breadcrumb-item>
        <my-breadcrumb-item to="/" :active="true" :isDisabled="true">{{
          $t("Passport.account")
        }}</my-breadcrumb-item>
      </my-breadcrumb>
      <div class="hintinfo" v-if="isshowhitinfo">
        <hint>{{ hintInfo }}</hint>
      </div>
      <div class="userform">
        <div class="login">
          <div class="title">{{ $t("Passport.SignIn") }}</div>
          <div class="loginInfo">
            <div class="group">
              <p class="hint">{{ $t("Passport.Account") }} *</p>
              <has-icon-input
                type="text"
                v-model="loginData.userName"
                :height="40"
              ></has-icon-input>
            </div>

            <div class="group">
              <p class="hint">{{ $t("Passport.Password") }} *</p>
              <has-icon-input
                type="password"
                v-model="loginData.userPassword"
                :height="40"
              ></has-icon-input>
            </div>

            <div class="isremember">
              <div class="fk" @click="remmember">
                <i class="iconfont icon-weigouxuan" v-if="!isRemmber"></i>
                <i class="iconfont icon-yigouxuan" v-else></i>
              </div>
              <span>{{ $t("Passport.Pages.Remember") }}</span>
            </div>

            <div class="btn" @click="login">{{ $t("Passport.SignIn") }}</div>

            <p class="lostpassword">{{ $t("Passport.Pages.FindPwd") }}</p>
          </div>
        </div>
        <div class="register">
          <div class="title">{{ $t("Passport.SignUp") }}</div>
          <div class="registerInfo">
            <!-- <div class="group">
              <p class="hint">{{$t('Passport.Mobile')}} *</p>
              <has-icon-input
                type="text"
                v-model="registerData.userMobile"
                :height="40"
              ></has-icon-input>
            </div> -->

            <div class="group">
              <p class="hint">{{ $t("Passport.Email") }} *</p>
              <has-icon-input
                type="email"
                v-model="registerData.userEmail"
                :height="40"
              ></has-icon-input>
            </div>

            <div class="group">
              <p class="hint">{{ $t("Passport.Password") }} *</p>
              <has-icon-input
                type="password"
                v-model="registerData.userPassword"
                :height="40"
              ></has-icon-input>
            </div>

            <div class="group">
              <p class="hint">{{ $t("Passport.ConfirmPassword") }} *</p>
              <has-icon-input
                type="password"
                v-model="registerData.userConfirmPassword"
                :height="40"
              ></has-icon-input>
            </div>

            <div class="group captcha">
              <img :src="captcha" alt="" @click="getcaptcha" />
              <has-icon-input
                type="text"
                v-model="registerData.userVcode"
                :height="40"
              ></has-icon-input>
            </div>

            <!-- <p class="reminder">
              Your personal data will be used to support your experience
              throughout this website, to manage access to your account, and for
              other purposes described in our privacy policy.
            </p> -->
            <div class="btn" @click="register">{{ $t("Passport.SignUp") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MyBreadcrumb, MyBreadcrumbItem } from "common/breadcrumb";
import HasIconInput from "common/input/HasIconInput";
import changeUserState from "@/common/addFun/disposeUserState";
import Hint from "./child/Hint";
import User from "model/User.js";
export default {
  data() {
    return {
      //登录是否记住用户
      isRemmber: true,

      //登录数据
      loginData: {
        userName: "",
        userPassword: "",
      },

      //注册数据
      registerData: {
        // userMobile: "",
        userEmail: "",
        userPassword: "",
        userConfirmPassword: "",
        userVcode: "",
      },

      //是否显示提示信息
      isshowhitinfo: false,
      //提示的文本
      hintInfo: "",

      //图形验证码数据
      captcha: "",
      //captcha返回的verify
      verify: "",
      //captch返回的真实验证码
      code: "",
    };
  },

  methods: {
    //点击了记住用
    remmember() {
      this.isRemmber = !this.isRemmber;
    },

    //点击登录按钮
    login() {
      if (this.loginData.userName == "" || this.loginData.userPassword == "") {
        return (() => {
          this.isshowhitinfo = true;
          this.loginData.userName == ""
            ? (this.hintInfo = "Username is required.")
            : (this.hintInfo = "The password field is empty.");
        })();
      } else {
        // 请求服务器
        this.goLogin();
      }
    },

    //点击注册按钮
    register() {
      //判断是否有内容为空
      let isEmpty = (() => {
        for (let item in this.registerData) {
          if (this.registerData[item] == "") {
            return {
              name: item,
              res: true,
            };
          }
        }
        return {
          res: false,
        };
      })();

      if (isEmpty.res) {
        return (() => {
          this.isshowhitinfo = true;
          this.hintInfo = `The ${isEmpty.name.replace("user", "")} is empty.`;
        })();
      }

      //判断邮箱是否正确
      let reg =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/;
      let emailIsTrue = reg.test(this.registerData.userEmail);
      if (!emailIsTrue) {
        return (() => {
          this.isshowhitinfo = true;
          this.hintInfo = `The email is illegal.`;
        })();
      }

      //判断两次密码是否一致
      let passwordIsOK =
        this.registerData.userPassword == this.registerData.userConfirmPassword;
      if (!passwordIsOK) {
        return (() => {
          this.isshowhitinfo = true;
          this.hintInfo = `The passwords are inconsistent.`;
        })();
      }

      //判断验证码是否一致  toLowerCase( )
      if (
        this.registerData.userVcode.toLowerCase() != this.code.toLowerCase()
      ) {
        return (() => {
          this.isshowhitinfo = true;
          this.hintInfo = `The verification codes are inconsistent.`;
        })();
      }

      //发送注册请求
      this.userRegister();
    },

    //请求图形验证码
    getcaptcha() {
      User.Passport.Captcha({
        // data:{
        //     service:"user.captcha"
        // },
        succ: (res) => {
          this.captcha = res.content;
          this.verify = res.verify;
          this.code = res.code;
          console.log(res);
        },
        fail: (res) => {
          this.isshowhitinfo = true;
          this.hintInfo = `network error!`;
        },
      });
    },

    //注册请求
    userRegister() {
      User.Passport.Register({
        data: {
          // username: this.registerData.userMobile,
          username: this.registerData.userEmail,
          password: this.registerData.userPassword,
          email: this.registerData.userEmail,
          repeat_password: this.registerData.userConfirmPassword,
          vcode: this.registerData.userVcode,
          verify: this.verify,
        },
        succ: (res) => {
          //在本地存储用户注册信息
          this.disposeUserInfo(res);
        },
        fail: (res) => {
          console.log(res);
        },
      });
    },

    //登录请求
    goLogin() {
      User.Passport.Login({
        data: {
          account: this.loginData.userName,
          password: this.loginData.userPassword,
        },
        succ: (res) => {
          this.disposeUserInfo(res);
        },
        fail: (res) => {
          console.log(res);
        },
      });
    },

    //登录或注册成功后处理用户信息
    disposeUserInfo(res) {
      let payload = {
        username: res.username,
        avatar: res.avatar,
        token: res.token,
      };

      if (this.isRemmber) {
        changeUserState.add(payload, "long");
      } else {
        changeUserState.add(payload, "short");
      }
    },

  },
  components: {
    MyBreadcrumb,
    MyBreadcrumbItem,
    HasIconInput,
    Hint,
  },
  created() {
    this.getcaptcha();
  },
};
</script>
<style lang='scss' scoped>
.account {
  background-color: white;
  padding-top: 1.5rem;
  padding-bottom: 3.5rem;
  .userform {
    margin-top: 1.25rem;
    display: flex;
    // justify-content:space-between;
    margin-left: -15px;
    margin-right: -15px;
    .login,
    .register {
      width: 50%;
      box-sizing: border-box;
      padding: 15px;
    }

    .title {
      font-size: 1rem;
      font-weight: 600;
      color: var(--text-color-title);
      padding-bottom: 0.75rem;
      border-bottom: 1px solid var(--border-color-default);
      margin-bottom: 1rem;
    }

    .hint {
      color: var(--text-color-minor);
      font-size: 0.75rem;
      margin-bottom: 0.75rem;
    }

    .group {
      margin-bottom: 1rem;
    }

    .isremember {
      margin-bottom: 0.75rem;
      display: flex;
      color: var(--text-color-minor);
      font-size: 0.75rem;
      .fk {
        margin-right: 0.4rem;
      }
    }

    .btn {
      background-color: var(--main-color);
      color: white;
      font-size: 0.875rem;
      display: inline-block;
      padding: 0.4rem 1rem;
      border-radius: 0.2rem;
      margin-bottom: 0.75rem;
      cursor: pointer;
    }

    .lostpassword {
      font-size: 0.75rem;
      color: var(--minor-color);
    }

    .reminder {
      font-size: 0.75rem;
      color: var(--text-color-minor);
      margin-bottom: 0.75rem;
    }
  }

  //设置提示框
  .hintinfo {
    margin-top: 1.5rem;
  }

  .captcha {
    display: flex;
    img {
      height: 40px;
      margin-right: 0.75rem;
    }
  }
}
</style>